<template>
	<Section
		id="NewsSection"
		:title="$t('news-section.title')"
		:subtitle="$t('news-section.subtitle')"
		img="./img/backgrounds/bg-news.jpg"
	>
		<div class="news-wrapper">
			<NewsCard
				v-up
				class="news"
				:title="$t('news-section.boards.b-4.title')"
				img="./img/news/visita-deputado.jpg"
				:alt="$t('news-section.boards.b-4.alt')"
				:date="$t('news-section.boards.b-4.date')"
				:department="$t('news-section.boards.b-4.department')"
				:preview="$t('news-section.boards.b-4.preview')"
				@open="handleOpenPopUp"
				:article="4"
			/>

			<NewsCard
				v-up
				class="news"
				:title="$t('news-section.boards.b-3.title')"
				img="./img/news/gptw2019.jpg"
				:alt="$t('news-section.boards.b-3.alt')"
				:date="$t('news-section.boards.b-3.date')"
				:department="$t('news-section.boards.b-3.department')"
				:preview="$t('news-section.boards.b-3.preview')"
				@open="handleOpenPopUp"
				:article="3"
			/>

			<NewsCard
				v-down
				class="news"
				:title="$t('news-section.boards.b-2.title')"
				img="./img/news/premio-cat.jpg"
				:alt="$t('news-section.boards.b-2.alt')"
				:date="$t('news-section.boards.b-2.date')"
				:department="$t('news-section.boards.b-2.department')"
				:preview="$t('news-section.boards.b-2.preview')"
				@open="handleOpenPopUp"
				:article="2"
			/>			

			<NewsCard
				v-up
				class="news"
				:title="$t('news-section.boards.b-1.title')"
				img="./img/news/gptwrs2018.jpg"
				:alt="$t('news-section.boards.b-1.alt')"
				:date="$t('news-section.boards.b-1.date')"
				:department="$t('news-section.boards.b-1.department')"
				:preview="$t('news-section.boards.b-1.preview')"
				@open="handleOpenPopUp"
				:article="1"
			/>

		</div>
		<PopUp id="NewsPopUp" @closed="handleClosePopUp" :title="$t('news-section.title')" v-if="isOpen">
			<Article4 v-if="article == 4" />
			<Article3 v-if="article == 3" />
			<Article2 v-if="article == 2" />
      <Article1 v-if="article == 1"/>
    </PopUp>
	</Section>
</template>

<script>
import components from "@/components/global";
import articles from './articles';
import NewsCard from "./NewsCard";

const { Section, PopUp } = components;

export default {
	name: 'NewsSection',
	components: {
		Section,
		NewsCard,
		PopUp,
		...articles
	},
	data() {
		return {
			isOpen: false,
			article: 0
		}
	},
	methods: {
		handleOpenPopUp(article) {
			this.article = article
			this.isOpen = true
		},
		handleClosePopUp() {
			this.isOpen = false
		}
	},
};
</script>

<style lang="css" scoped>
.news-wrapper {
	display: grid;
	grid-template-columns: 1fr 1fr 1fr;
	grid-column-gap: 30px;
}

.news {
	margin-top: 25px;
}

@media screen and (max-width: 1100px) {
	.news-wrapper {
		display: grid;
		grid-template-columns: 49% 49%;
		grid-column-gap: 2%;
	}

	.news {
		margin-top: 15px;
	}
}

@media screen and (max-width: 550px) {
	.news-wrapper {
		display: block;
	}
}
</style>