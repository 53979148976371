<template>
  <Section id="QualitySection" :title="$t('quality-section.title')" img="./img/backgrounds/bg-qualidade.jpg" >
    <p v-html="$t('quality-section.p-1')"></p>
    <p v-html="$t('quality-section.p-2')"></p>
    <div class="boards">
      <div v-right class="cert-board">
        <Certification 
          class="certification-board"
          img="./img/bg/farmbg.jpg" 
          icon="./img/icons/lnr-checkmark-circle.svg" 
          :alt="$t('quality-section.certifications.alts.check')"
          name="ISO 9001:2015"
          :description="$t('quality-section.certifications.iso-9001')"
          document="./files/iso9001.pdf"
        />
      </div>
      <div v-down class="cert-board">
        <Certification
          img="./img/bg/autobg.jpg" 
          icon="./img/icons/lnr-car.svg" 
          :alt="$t('quality-section.certifications.alts.car')" 
          name="IATF 16949:2016"
          :description="$t('quality-section.certifications.iatf')"
          document="./files/iatf16949.pdf"
        />
      </div>
      <div v-left class="cert-board">
        <Certification 
          img="./img/bg/naturebg.jpg" 
          icon="./img/icons/lnr-leaf.svg" 
          :alt="$t('quality-section.certifications.alts.leaf')"
          name="ISO 14001:2015"
          :description="$t('quality-section.certifications.iso-14001')"
          document="./files/iso14001-2025.pdf"
        />
      </div>
    </div>
    <p class="p-3" v-html="$t('quality-section.p-3')"></p>
    <div v-grow class="boards">
      <a class="board" @click="() => handleOpenPopUp({title: $t('quality-section.boards.quality.title'), image: './img/quality/politica-integrada.png'})">
        <p class="board-title" v-html="$t('quality-section.boards.quality.title')"></p>
        <p class="board-body" v-html="$t('quality-section.boards.quality.body')"></p>
      </a>
      <a class="board" @click="() => handleOpenPopUp({title: $t('quality-section.boards.environment.title'), document: ''})" >  
        <p class="board-title" v-html="$t('quality-section.boards.environment.title')"></p>
        <p class="board-body" v-html="$t('quality-section.boards.environment.body')"></p>
      </a>
      <a class="board" href="./files/manual_fornecedores.pdf" target="_blank">
        <p class="board-title" v-html="$t('quality-section.boards.supplier_manual.title')"></p>
        <p class="board-body" v-html="$t('quality-section.boards.supplier_manual.body')"></p>
      </a>
    </div>
    <PopUp id="QualityPopUp" @closed="handleClosePopUp" :title="title" v-if="isOpen">
      <object v-if="document" :data="document" type="application/pdf" width="100%" height="450px"></object>
      <img width="100%" v-else-if="image" :src="image" :alt="title">
      <div v-else>
        <hr />
        <p class="popup-p" v-html="$t('quality-section.environmental-popup.p-1')"></p>
        <p class="popup-p" v-html="$t('quality-section.environmental-popup.p-2')"></p>
        <p class="popup-p" v-html="$t('quality-section.environmental-popup.p-3')"></p>
        <p class="popup-p" v-html="$t('quality-section.environmental-popup.p-4')"></p>
      </div>
    </PopUp>
  </Section>
</template>

<script>
  import components from '@/components/global';
  import Certification from './Certification';

  const { Section, PopUp } = components;
  
  export default {
    components: {
      Section,
      Certification,
      PopUp,
    },
    data() {
      return {
        isOpen: false,
        document: '',
        image: '',
        title: ''
      };
    },
    methods: {
      handleOpenPopUp({title, document, image}) {
        this.isOpen = true;
        this.document = document;
        this.title = title;
        this.image = image;
      },
      handleClosePopUp() {
        this.isOpen = false;
      },
    }
  }
</script>

<style lang="css" scoped>
  a {
    text-decoration: none;
  }

  #QualitySection p {
    margin: 3px;
    text-align: center;
  }

  #QualitySection .boards {
    display: flex;
    justify-content: space-between;
    margin-top: 25px;
  }

  #QualitySection .p-3 {
    margin-top: 30px;
    float: left;
    width: 50%;
  }

  #QualitySection .board {
    background-color: var(--blue);
    color: white;
    width: 45%;
    font-weight: 500;
    padding: 10px;
    padding-bottom: 25px;
    border-radius: 10px;
    transition: box-shadow 0.5s ease;
    margin: 0 3px 0 3px;
  }

  #QualitySection .board:focus,
  #QualitySection .board:hover {
    cursor: pointer;
    box-shadow: 0px 0px 20px 0px #999;
  }

  #QualitySection .board-title{
    font-size: 18px;
  }

  #QualitySection .board-body {
    margin-top: 7px;
  }

  #QualitySection .popup-p {
    text-align: left;
    margin-top: 20px;
  }

  .boards .cert-board {
    width:31%;
  }

  @media screen and (max-width: 900px) {
    #QualitySection  .boards{
      width: 100%;
      flex-direction: column;
    }

    #QualitySection .cert-board {
      margin-top: 20px;
      width: 100%;
    }

    #QualitySection .p-3 {
      float: none;
      width: 100%;
    }

    #QualitySection .board {
      margin: 5px;
      width: 100%;
    } 

    #QualitySection .board + .board {
      margin-top: 20px;
    }

  }


</style>